import request from '@/utils/request'

// 用户[账单]签约数据汇总
export function billSummaryDetail (params) {
  return request.post('/summary/billSummaryDetail', params)
}

// 还款数据汇总
export function repaymentSummaryDetail (params) {
  return request.post('/summary/repaymentSummaryDetail', params)
}

// 平台分账汇总
export function platformLedgerSummaryDetail (params) {
  return request.post('/summary/platformLedgerSummaryDetail', params)
}

// 商家分账汇总
export function businessLedgerSummaryDetail (params) {
  return request.post('/summary/businessLedgerSummaryDetail', params)
}

// 剩余本金汇总
export function remainPrincipalSummaryAmount (params) {
  return request.post('/summary/remainPrincipalSummaryAmount', params)
}

// 月度未还汇总
export function monthUnpaidSummaryList (params) {
  return request.post('/summary/monthUnpaidSummaryList', params)
}

// 月度已还汇总
export function monthRepaidSummaryList (params) {
  return request.post('/summary/monthRepaidSummaryList', params)
}
