<template>
  <div class="monthAlreadySummarization">
    <div>
      <el-form class="flex"
        ref="searchRef"
        :model="searchForm"
        label-width="80px"
        size="small"
      >
        <!-- <el-row :gutter="20"> -->
          <!-- <el-col :span="6"> -->
            <el-form-item label="月份">
              <el-date-picker
                v-model="collectMonth"
                type="monthrange"
                range-separator="-"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                value-format="yyyyMM"
                @change="initData"
                :picker-options="setDateRange"
              >
              </el-date-picker>
            </el-form-item>
          <!-- </el-col> -->
          <div class="ml_20">
            <el-button type="primary" size="small" @click="initData"
              >查询</el-button
            >
          </div>
        <!-- </el-row> -->
      </el-form>
    </div>
    <div class="auth-table">
      <el-table :data="tableData" header-cell-class-name="table_header" border v-loading="loading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, .6)">
        <el-table-column prop="date" label="月份" />
        <el-table-column prop="trade_amount" label="交易总金额" />
        <el-table-column prop="trade_principal" label="还款本金" />
        <el-table-column prop="platform_income" label="平台收入" />
        <el-table-column prop="platform_interest" label="平台管理费" />
        <el-table-column prop="user_payer_interest" label="平台管理费(用户承担)" />
        <el-table-column prop="business_payer_interest" label="平台管理费(商家贴息)" />
        <el-table-column prop="business_interest" label="商家管理费" />
        <!-- <el-table-column prop="overdue_amount" label="逾期金额" /> -->
        <el-table-column prop="overdue_late_fee" label="逾期滞纳金" />
        <el-table-column prop="overdue_fine" label="逾期罚金" />
        <el-table-column prop="advance_interest" label="提前还款手续费" />
        <el-table-column prop="refund_platform_income" label="退款手续费(平台)" />
        <el-table-column prop="refund_business_income" label="退款手续费(商家)" />
      </el-table>
    </div>
  </div>
</template>

<script>
import { monthRepaidSummaryList } from "@/api/dataSummarization.js";
export default {
  name: "monthAlreadySummarization",

  data() {
    return {
      loading:false,
      tableData: [],
      collectMonth: [],
      searchForm: {
        startMonth: "",
        endMonth: "",
      },
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  mounted() {
    this.initData();
  },

  methods: {
    async initData() {
      if (this.collectMonth && this.collectMonth.length == 2) {
        this.searchForm.startMonth = this.collectMonth[0]
        this.searchForm.endMonth   = this.collectMonth[1]
      } else {
        this.searchForm.startMonth = "";
        this.searchForm.endMonth   = "";
      }
      this.loading = true
      const { code, data } = await monthRepaidSummaryList(this.searchForm);
      this.loading = false

      if (code == 200) {
        this.tableData = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.monthAlreadySummarization {
  .auth-table {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>